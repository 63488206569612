<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-col-gutter-sm">
        <q-select
          :options="$LIST_JENJANG"
          v-model="selJenjang"
          outlined
          label="Jenjang"
          @input="pilihSumber"
        ></q-select>
        <q-select
          :options="listSumber"
          v-model="selSumber"
          outlined
          label="Sumber Data"
        ></q-select>
        <q-file
          outlined
          label="Klik Untuk Pilih File"
          stack-label
          @input="importFile"
          v-model="file"
        >
          <template v-slot:prepend>
            <q-icon name="cloud_upload" />
          </template>
        </q-file>
      </q-card-section>
      <q-card-actions>
        <q-btn
          v-show="selSumber == 'Pribadi'"
          icon="download"
          flat
          dense
          :label="'download format Pribadi ' + selJenjang"
          @click="getTingkat(selJenjang)"
        ></q-btn>
        <q-btn
          v-show="selSumber == 'Import MA'"
          icon="download"
          flat
          dense
          type="a"
          href="./file_import/Format_MA.xlsx"
          :label="'download Format Import MA'"
          @click="downloadMA"
        ></q-btn>
      </q-card-actions>
      <q-card-actions class="row justify-end">
        <q-btn flat color="primary" label="Cancel" @click="onCancelClick" />
        <q-btn
          unelevated
          color="primary"
          @click="onOKClick"
          :disable="file == null"
          label="baca file"
        ></q-btn>
      </q-card-actions>
    </q-card>
    <q-dialog persistent v-model="pilTingkat">
      <q-card>
        <q-card-section>
          <q-select
            outlined
            stack-label
            label="Tingkat"
            v-model="selTingkat"
            :options="optTingkat"
            style="min-width: 300px"
          ></q-select>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat color="primary" label="Close" v-close-popup></q-btn>
          <q-btn
            unelevated
            color="primary"
            :disable="selTingkat == null"
            @click="downloadFormat(selJenjang)"
            label="Download"
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-dialog>
</template>

<script>
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
export default {
  props: {},
  data() {
    return {
      selTingkat: null,
      pilTingkat: false,
      optTingkat: [],

      file: null,
      listSumber: ["Dapodik", "EMIS", "Pribadi"],
      // "PPDB",
      selJenjang: this.$LIST_JENJANG[0],
      selSumber: "",
    };
  },
  mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    this.pilihSumber();
    this.$q.loading.hide();
  },
  methods: {
    pilihSumber() {
      if (this.selJenjang == "MA") {
        this.listSumber = ["Import MA", "EMIS", "Pribadi"];
        this.selSumber = "Import MA";
      } else {
        this.listSumber = ["Dapodik", "Pribadi"];
        this.selSumber = "Dapodik";
      }
    },
    importFile(file) {
      this.file = file;
    },

    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit("hide");
    },

    onOKClick() {
      let data = {
        sumber: this.selSumber,
        jenjang: this.selJenjang,
        file: this.file,
      };
      this.$emit("ok", data);
      this.hide();
    },

    onCancelClick() {
      this.hide();
    },
    async getTingkat(jenjang) {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.selJenjang = jenjang;
      let temp = [];
      let resp = await this.$http.get(`/siswa/import/gettingkat/${jenjang}`);
      for (let item of resp.data) {
        temp.push(item.tingkat);
      }
      this.optTingkat = temp;
      this.pilTingkat = true;
      this.$q.loading.hide();
    },
    async downloadMA() {
      // this.$q.loading.show({ message: "Mohon Tunggu..." });
      // const workbook = new ExcelJS.Workbook();
      // const sheetSiswa = workbook.addWorksheet("NISN SISWA");
      // sheetSiswa.columns = [
      //   { header: "NISN", key: "nisn", width: 20 },
      //   { header: "NIK", key: "nik", width: 20 },
      //   { header: "NIPD", key: "nipd", width: 20 },
      //   { header: "NAMA", key: "nama", width: 32 },
      //   { header: "GENDER", key: "gender", width: 10 },
      //   { header: "TINGKAT", key: "tingkat", width: 10 },
      //   { header: "PEMINATAN", key: "peminatan", width: 15 },
      // ];
      // let poolPeminatan = "-,IPA,IPS,AGAMA";
      // let poolGender = "L,P";
      // let poolTingkat = "10,11,12";
      // for (let i = 1; i < 1000; i++) {
      //   let carier = {
      //     nisn: "",
      //     nik: "",
      //     nipd: "",
      //     nama: "",
      //     gender: "",
      //     tingkat: "",
      //     peminatan: "",
      //   };
      //   let row = Object.keys(carier).map((key) => {
      //     return carier[key];
      //   });
      //   sheetSiswa.addRow(row);
      // }
      // // for (let item of fullList) {
      // //   let carier = {};
      // //   for (let key of sheetSiswa.columns) {
      // //     carier[key.key] = item[key.key];
      // //   }
      // //   let row = Object.keys(carier).map((key) => {
      // //     return carier[key];
      // //   });
      // //   sheetSiswa.addRow(row);
      // // }
      // sheetSiswa.getColumn("E").eachCell(function(cell, idx) {
      //   cell.dataValidation = {
      //     type: "list",
      //     operator: "Equal",
      //     showErrorMessage: true,
      //     allowBlank: true,
      //     formulae: [`\"${poolGender}\"`],
      //     errorStyle: "error",
      //     errorTitle: "Data tidak valid",
      //     error: "Pastikan penulisan sesuai dengan Pilihan",
      //   };
      // });
      // sheetSiswa.getColumn("F").eachCell(function(cell, idx) {
      //   cell.dataValidation = {
      //     type: "list",
      //     operator: "Equal",
      //     showErrorMessage: true,
      //     allowBlank: true,
      //     formulae: [`\"${poolTingkat}\"`],
      //     errorStyle: "error",
      //     errorTitle: "Data tidak valid",
      //     error: "Pastikan penulisan sesuai dengan Pilihan",
      //   };
      // });
      // sheetSiswa.getColumn("G").eachCell(function(cell, idx) {
      //   cell.dataValidation = {
      //     type: "list",
      //     operator: "Equal",
      //     showErrorMessage: true,
      //     allowBlank: true,
      //     formulae: [`\"${poolPeminatan}\"`],
      //     errorStyle: "error",
      //     errorTitle: "Data tidak valid",
      //     error: "Pastikan penulisan sesuai dengan Pilihan",
      //   };
      // });
      // const name = `Format Import MA.xlsx`;
      // const buffer = await workbook.xlsx.writeBuffer();
      // const fileType =
      //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      // const blob = new Blob([buffer], { type: fileType });
      // saveAs(blob, name);
      // this.$q.loading.hide();
    },
    async downloadFormat(jenjang) {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      const workbook = new ExcelJS.Workbook();
      const sheetSiswa = workbook.addWorksheet("SISWA");
      const sheetAyah = workbook.addWorksheet("AYAH");
      const sheetIbu = workbook.addWorksheet("IBU");
      const sheetWali = workbook.addWorksheet("WALI");
      sheetSiswa.columns = [
        { header: "Id", key: "id", width: 10 },
        { header: "NISN", key: "nisn", width: 15 },
        { header: "NIK", key: "nik", width: 20 },
        { header: "NIPD", key: "nipd", width: 10 },
        { header: "NAMA", key: "nama", width: 32 },
        { header: "PEMINATAN", key: "peminatan", width: 32 },
        { header: "GENDER", key: "gender", width: 10 },
        { header: "ANAK KE", key: "anak_ke", width: 10 },
        { header: "BERSAUDARA", key: "bersaudara", width: 10 },
        { header: "TEMPAT LAHIR", key: "tempat_lahir", width: 15 },
        { header: "TANGGAL LAHIR", key: "tanggal_lahir", width: 15 },
        { header: "ALAMAT", key: "alamat", width: 40 },
        { header: "RT", key: "rt", width: 5 },
        { header: "RW", key: "rw", width: 5 },
        { header: "DUSUN", key: "dusun", width: 15 },
        { header: "KELURAHAN", key: "kelurahan", width: 15 },
        { header: "KECAMATAN", key: "kecamatan", width: 15 },
        { header: "KABUPATEN", key: "kabupaten", width: 15 },
        { header: "PROVINSI", key: "provinsi", width: 15 },
        { header: "KODE POS", key: "kode_pos", width: 10 },
        { header: "SEKOLAH ASAL", key: "sekolah_asal", width: 20 },
        { header: "TAHUN MASUK", key: "tahun_masuk", width: 10 },
        { header: "TINGKAT", key: "tingkat", width: 10 },
        { header: "TIPE ANGKATAN", key: "tipe_angkatan", width: 20 },
      ];
      const sheetKeluarga = [
        { header: "ID SISWA", key: "id_siswa", width: 10 },
        { header: "NISN", key: "nisn", width: 15 },
        { header: "SISWA", key: "siswa", width: 35 },
        { header: "ID_KELUARGA", key: "id_keluarga", width: 10 },
        { header: "NIK", key: "nik", width: 15 },
        { header: "NAMA", key: "nama", width: 35 },
        { header: "TEMPAT LAHIR", key: "tempat_lahir", width: 15 },
        { header: "TANGGAL LAHIR", key: "tanggal_lahir", width: 15 },
        { header: "KEPEMILIKAN RUMAH", key: "kepemilikan_rumah", width: 15 },
        { header: "STATUS PERKAWINAN", key: "status_perkawinan", width: 15 },
        { header: "ALAMAT", key: "alamat", width: 35 },
        { header: "HP", key: "hp", width: 15 },
        { header: "EMAIL", key: "email", width: 15 },
        { header: "FB", key: "fb", width: 10 },
        { header: "PENDIDIKAN", key: "pendidikan", width: 10 },
        { header: "PEKERJAAN", key: "pekerjaan", width: 20 },
        { header: "JABATAN", key: "jabatan", width: 15 },
        { header: "TINGKAT JABATAN", key: "tingkat_jabatan", width: 15 },
        { header: "PENGHASILAN", key: "penghasilan", width: 30 },
        { header: "ORGANISASI", key: "organisasi", width: 20 },
        { header: "JABATAN ORGANISASI", key: "jabatan_organisasi", width: 15 },
      ];
      sheetAyah.columns = sheetKeluarga;
      sheetIbu.columns = sheetKeluarga;
      sheetWali.columns = sheetKeluarga;

      // get list siswa dan keluarga
      let siswa = [];
      let ayah = [];
      let ibu = [];
      let wali = [];

      let resp = await this.$http.get(
        `/siswa/formatimport/${jenjang}/${this.selTingkat}`,
        this.$hlp.getHeader()
      );
      siswa = resp.data;
      resp = await this.$http.get(
        `/keluarga/formatimport/${jenjang}/${this.selTingkat}/ayah`,
        this.$hlp.getHeader()
      );
      ayah = resp.data;
      resp = await this.$http.get(
        `/keluarga/formatimport/${jenjang}/${this.selTingkat}/ibu`,
        this.$hlp.getHeader()
      );
      ibu = resp.data;
      resp = await this.$http.get(
        `/keluarga/formatimport/${jenjang}/${this.selTingkat}/wali`,
        this.$hlp.getHeader()
      );
      wali = resp.data;

      let poolTingkat = "";
      let poolGender = "";
      let poolPeminatan = "IPA,IPS,AGAMA";

      // get list gender
      let listGender = [{ items: "L" }, { items: "P" }];
      if (jenjang == "SMP PA") {
        listGender = [{ items: "L" }];
      } else if (jenjang == "SMP PI") {
        listGender = [{ items: "P" }];
      }
      for (let items of listGender) {
        poolGender = poolGender + items.items + ",";
      }

      // get list tingkat
      let listTingkat = [{ items: "7" }, { items: "8" }, { items: "9" }];
      if (jenjang == "MA") {
        listTingkat = [{ items: "10" }, { items: "11" }, { items: "12" }];
      }
      for (let items of listTingkat) {
        poolTingkat = poolTingkat + items.items + ",";
      }

      let fullList = siswa.slice(0);
      for (let item of fullList) {
        let carier = {};
        for (let key of sheetSiswa.columns) {
          carier[key.key] = item[key.key];
        }
        let row = Object.keys(carier).map((key) => {
          return carier[key];
        });
        sheetSiswa.addRow(row);
      }

      fullList = ayah.slice(0);
      for (let item of fullList) {
        let carier = {};
        for (let key of sheetAyah.columns) {
          carier[key.key] = item[key.key];
        }
        let row = Object.keys(carier).map((key) => {
          return carier[key];
        });
        sheetAyah.addRow(row);
      }

      fullList = ibu.slice(0);
      for (let item of fullList) {
        let carier = {};
        for (let key of sheetIbu.columns) {
          carier[key.key] = item[key.key];
        }
        let row = Object.keys(carier).map((key) => {
          return carier[key];
        });
        sheetIbu.addRow(row);
      }

      fullList = wali.slice(0);
      for (let item of fullList) {
        let carier = {};
        for (let key of sheetWali.columns) {
          carier[key.key] = item[key.key];
        }
        let row = Object.keys(carier).map((key) => {
          return carier[key];
        });
        sheetWali.addRow(row);
      }

      let poolPekerjaan = [
        "Tidak bekerja",
        "Buruh",
        "Karyawan Swasta",
        "Lainnya",
        "Nelayan",
        "Pedagang Besar",
        "Pedagang Kecil",
        "Pensiunan",
        "Petani",
        "PNS/TNI/Polri",
        "Sudah Meninggal",
        "Wiraswasta",
        "Wirausaha",
        "Tidak dapat diterapkan",
      ];

      let poolPendidikan = [
        "Tidak sekolah",
        "SD / sederajat",
        "SMP / sederajat",
        "SMA / sederajat",
        "D1",
        "D2",
        "D3",
        "D4",
        "S1",
        "S2",
        "S3",
      ];

      let poolPenghasilan = [
        "Tidak Berpenghasilan",
        "Kurang dari Rp. 500,000",
        "Rp. 500,000 - Rp. 999,999",
        "Rp. 1,000,000 - Rp. 1,999,999",
        "Rp. 2,000,000 - Rp. 4,999,999",
        "Rp. 5,000,000 - Rp. 20,000,000",
        "Lebih dari Rp. 20,000,000",
      ];

      let poolJabatan = [
        "Legislatif",
        "Eksekutif",
        "Kepala Daerah",
        "Birokrat",
        "Eselon 1",
        "Eselon 2",
        "Eselon 3",
        "Eselon 4",
        "BUMN",
        "Pengusaha",
        "PNS",
        "Lainya",
      ];

      for (let i = 1; i <= poolJabatan.length; i++) {
        const cell_ayah = sheetAyah.getCell(`ZW${i}`);
        const cell_ibu = sheetIbu.getCell(`ZW${i}`);
        const cell_wali = sheetWali.getCell(`ZW${i}`);
        cell_ayah.value = poolJabatan[i - 1];
        cell_ibu.value = poolJabatan[i - 1];
        cell_wali.value = poolJabatan[i - 1];
      }

      for (let i = 1; i <= poolPekerjaan.length; i++) {
        const cell_ayah = sheetAyah.getCell(`ZX${i}`);
        const cell_ibu = sheetIbu.getCell(`ZX${i}`);
        const cell_wali = sheetWali.getCell(`ZX${i}`);
        cell_ayah.value = poolPekerjaan[i - 1];
        cell_ibu.value = poolPekerjaan[i - 1];
        cell_wali.value = poolPekerjaan[i - 1];
      }

      for (let i = 1; i <= poolPendidikan.length; i++) {
        const cell_ayah = sheetAyah.getCell(`ZY${i}`);
        const cell_ibu = sheetIbu.getCell(`ZY${i}`);
        const cell_wali = sheetWali.getCell(`ZY${i}`);
        cell_ayah.value = poolPendidikan[i - 1];
        cell_ibu.value = poolPendidikan[i - 1];
        cell_wali.value = poolPendidikan[i - 1];
      }

      for (let i = 1; i <= 7; i++) {
        const cell_ayah = sheetAyah.getCell(`ZZ${i}`);
        const cell_ibu = sheetIbu.getCell(`ZZ${i}`);
        const cell_wali = sheetWali.getCell(`ZZ${i}`);
        cell_ayah.value = poolPenghasilan[i - 1];
        cell_ibu.value = poolPenghasilan[i - 1];
        cell_wali.value = poolPenghasilan[i - 1];
      }

      sheetSiswa.getColumn("G").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          allowBlank: true,
          formulae: [`\"${poolGender}\"`],

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai dengan Pilihan",
        };
      });

      sheetSiswa.getColumn("V").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          allowBlank: true,
          formulae: [`\"${poolTingkat}\"`],

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai dengan Pilihan",
        };
      });

      let poolAngkatan = "BARU,LANJUTAN SD";
      if (jenjang == "MA") {
        poolAngkatan = "BARU,LANJUTAN SMP";
      }
      sheetSiswa.getColumn("W").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          allowBlank: true,
          formulae: [`\"${poolAngkatan}\"`],

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai dengan Pilihan",
        };
      });

      if (jenjang == "MA") {
        sheetSiswa.getColumn("F").eachCell(function(cell, idx) {
          cell.dataValidation = {
            type: "list",
            operator: "Equal",
            showErrorMessage: true,
            allowBlank: true,
            formulae: [`\"${poolPeminatan}\"`],

            errorStyle: "error",
            errorTitle: "Data tidak valid",
            error: "Pastikan penulisan sesuai dengan Pilihan",
          };
        });
      }

      sheetAyah.getColumn("P").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          allowBlank: true,
          formulae: [`$ZX$1:$ZX$${poolPekerjaan.length}`],

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai dengan Pilihan",
        };
      });
      sheetIbu.getColumn("P").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          allowBlank: true,
          formulae: [`$ZX$1:$ZX$${poolPekerjaan.length}`],

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai dengan Pilihan",
        };
      });
      sheetWali.getColumn("P").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          allowBlank: true,
          formulae: [`$ZX$1:$ZX$${poolPekerjaan.length}`],

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai dengan Pilihan",
        };
      });
      sheetAyah.getColumn("O").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          allowBlank: true,
          formulae: [`$ZY$1:$ZY$${poolPendidikan.length}`],

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai dengan Pilihan",
        };
      });
      sheetIbu.getColumn("O").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          allowBlank: true,
          formulae: [`$ZY$1:$ZY$${poolPendidikan.length}`],

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai dengan Pilihan",
        };
      });
      sheetWali.getColumn("O").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          allowBlank: true,
          formulae: [`$ZY$1:$ZY$${poolPendidikan.length}`],

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai dengan Pilihan",
        };
      });

      sheetAyah.getColumn("R").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          allowBlank: true,
          formulae: [`$ZW$1:$ZW$${poolJabatan.length}`],

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai dengan Pilihan",
        };
      });
      sheetIbu.getColumn("R").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          allowBlank: true,
          formulae: [`$ZW$1:$ZW$${poolJabatan.length}`],

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai dengan Pilihan",
        };
      });
      sheetWali.getColumn("R").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          allowBlank: true,
          formulae: [`$ZW$1:$ZW$${poolJabatan.length}`],

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai dengan Pilihan",
        };
      });

      sheetAyah.getColumn("S").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          allowBlank: true,
          formulae: [`$ZZ$1:$ZZ$${poolPenghasilan.length}`],

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai dengan Pilihan",
        };
      });
      sheetIbu.getColumn("S").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          allowBlank: true,
          formulae: [`$ZZ$1:$ZZ$${poolPenghasilan.length}`],

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai dengan Pilihan",
        };
      });
      sheetWali.getColumn("S").eachCell(function(cell, idx) {
        cell.dataValidation = {
          type: "list",
          operator: "Equal",
          showErrorMessage: true,
          allowBlank: true,
          formulae: [`$ZZ$1:$ZZ$${poolPenghasilan.length}`],

          errorStyle: "error",
          errorTitle: "Data tidak valid",
          error: "Pastikan penulisan sesuai dengan Pilihan",
        };
      });

      // tulis ke file
      const name = `Format  ${jenjang} Tingkat ${this.selTingkat}.xlsx`;
      const buffer = await workbook.xlsx.writeBuffer();
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const blob = new Blob([buffer], { type: fileType });
      saveAs(blob, name);
      this.pilTingkat = false;
      this.selTingkat = null;
      this.$q.loading.hide();
    },
  },
};
</script>
